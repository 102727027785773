import React, { useState } from "react"
import AppBar from "../../components/appbar"
import Header from "../../components/header"
import { Scheduler, TimelineView } from '@progress/kendo-react-scheduler';
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Day } from "@progress/kendo-date-math";
import { CustomFooter } from "./tankWashSchedulerFooter";
import "@progress/kendo-theme-default/dist/all.css";

const baseData = [{
  "Location": "BUCOOH",
  "Wash ID": 227980,
  "Trailer": 6733,
  "Int Wash Type": "PLSLDL",
  "Start": "2022-09-13T12:00:00.000Z",
  "End": "2022-09-13T14:30:00.000Z"
}];

const currentYear = new Date().getFullYear();

const parseAdjust = eventDate => {
  const date = new Date(eventDate);
  date.setFullYear(currentYear);
  return date;
};

const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const displayDate = new Date(Date.UTC(currentYear, 8, 13));

const sampleDataWithResources = baseData.map(dataItem => ({
  id: dataItem.TaskID,
  start: parseAdjust(dataItem.Start),
  end: parseAdjust(dataItem.End),
  title: "Trailer " + dataItem.Trailer.toString() + " | " + dataItem["Int Wash Type"],
  description: dataItem["Int Wash Type"],
  roomId: randomInt(1, 2),
  personId: randomInt(3, 4)
}));

export const SchedulerConfigContext = React.createContext({
  slotDuration: [60, (_) => {}],
  slotDivision: [1, (_) => {}],
});

const tankWash = () => {
  const [slotDuration, setSlotDuration] = React.useState(60);
  const [slotDivision, setSlotDivision] = React.useState(2);
  const [isTankWashClicked, setIsTankWashClicked] = useState(false)
  const [hamburgerMenuClicked, sethamburgerMenuClicked] = useState(false)
  const handelcallbackFromHeader = async (childdata) => {
      setIsTankWashClicked(childdata)
  }

  return (
      <>
          <div id="wrapper">
              <Header
                  userclicked={isTankWashClicked}
                  parentcallback={handelcallbackFromHeader}></Header>
              <AppBar hamburgerMenuClicked={hamburgerMenuClicked}></AppBar>
              <div className="content-page-tankwash">
                  <div className="tw-header tw-header-curved">
                      <div className="tw-header-text">
                          <p className="tw-date-text">🡸</p>
                          <p className="tw-date-text">Tuesday, Sep 13, 2022</p>
                          <p className="tw-date-text">🡺</p>
                      </div>
                  </div>

                  <SchedulerConfigContext.Provider
                    value={{
                      slotDuration: [slotDuration, setSlotDuration],
                      slotDivision: [slotDivision, setSlotDivision],
                    }}
                  >
                    <Scheduler
                      data={sampleDataWithResources}
                      defaultDate={displayDate}
                      editable={{
                        remove: true,
                        resize: false,
                        add: false,
                        edit: false,
                        select: false,
                        drag: true,
                      }}
                      height={500}
                      header={(props)=><React.Fragment />}
                      footer={(props)=><CustomFooter {...props} />}
                    >
                      <TimelineView
                        key={0}
                        title="60m"
                        columnWidth={75}
                        slotDuration={slotDuration}
                        slotDivisions={slotDivision}
                        workDayStart={"09:00"}
                        workDayEnd={"17:00"}
                        workWeekStart={Day.Sunday}
                        workWeekEnd={Day.Monday}
                        showWorkHours={false}
                      />
                    </Scheduler>
                  </SchedulerConfigContext.Provider>

                  <div className="tw-header">
                      <div className="tw-header-text">
                          <p className="tw-date-text">Work Orders</p>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}

export default tankWash;
