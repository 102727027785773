import React, { useState, useEffect, useContext } from 'react';
import Modal from "react-bootstrap/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { DateTime } from "luxon";
import Button from "react-bootstrap/Button";
import TerminalService from "../../services/terminalService";
import { Autocomplete, TreeView, TreeItem } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import UserService from "../../services/userService";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { ContextData } from "../../components/appsession";
//import PlanningProfileList from "../planningProfileList/planningProfileList";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PlanningProfile from "../planningProfileTable/planningProfile";
import ProgressBar from "react-bootstrap/ProgressBar";

const PlanningProfileList = (props) => {
  // const {planningProfile, setPlanningProfile} = props
  const user = props.user;
  const updateUser = props.updateUser;

  const planningdata = useContext(ContextData);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false)
  const [allTerminal, setAllTerminal] = useState([]);
  const [terminal_id, setTerminal_id] = useState()
  const [planningDataCg, setPlanningDataCg] = useState([])
  const [planningDataShippers, setPlanningDataShippers] = useState([])
  const [planningProfile, setPlanningProfile] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [planningprofileName, setPlanningprofileName] = useState();
  const [selectedShippers, setSelectedShippers] = useState([]);
  const [editplannigdata, setPlanningdata] = useState([]);
  const [isOpenDeleteModalForPF, setIsOpenDeleteModalForPF] = useState(false)
  const [planningProfileDataForDelete, setPlnningProfileDataForDelete] =useState("")
  const [editeddefaultplanner, setediteddefaultplanner] = useState();
  const [isLoading, setIsLoading] = useState(true);

  console.log('planningDataCg', planningDataCg);

  useEffect(() => {
    const terminal = new TerminalService();
    terminal.getAllTerminals().then(res => setAllTerminal(res))
    const allPlannerProfiles = new UserService();
    allPlannerProfiles.getAllPlanningProfiles().then(res => setPlanningProfile(res));
  }, [planningProfile.length])

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
  });
  const [dataResult, setDataResult] = useState(process(planningProfile, dataState));
  const dataStateChange = (event) => {
    setDataResult(process(planningProfile, event.dataState));
    setDataState(event.dataState);
    setIsLoading(false);
  };

  useEffect(() => {
    setDataResult(process(planningProfile, dataState));
  }, [planningProfile]);

  const created_atplanningtable = (props) => {
    let date = Date.parse(props.dataItem.created_at)

    if (isNaN(date)) {
      return (
        <td>      { }
        </td>)
    }
    else {
      return (
        <td>      {DateTime.fromMillis(parseInt(date)).toFormat("MM-dd-yyyy,hh:mm a").toString()}
        </td>)
    }

  }

  const handleProfileNameChange = (event) => {
    setPlanningprofileName(event.target.value);
  }

  const handleChange = async (e, value) => {
    setTerminal_id(value.code);
    const userService = new UserService();
    const response = await userService.planningProfileByTerminalId(value.code)
    const commodity_groups = response.data.data.commodity_groups;
    const shippers = response.data.data.shippers.slice(0, 100);
    setPlanningDataCg(commodity_groups);
    setPlanningDataShippers(shippers);
    setModalOpen(true)
  }
  const defaultplannerHandlerForEdit = (e) => {
    console.log("ccc",e.target.checked)
    setediteddefaultplanner(e.target.checked)
}
const commodityData = {
  id: "cg_top",
  name: "Commodity",
  children: planningDataCg.map((cg_item) => {
    return {
      id: cg_item.code,
      name: cg_item.description,
    }
  }),
}
function getChildById(node, id) {
  let array = []

  function getAllChildCommodity(nodes) {
    if (nodes === null) return []
    array.push(nodes.id)
    if (Array.isArray(nodes.children)) {
      nodes.children.forEach((node) => {
        array = [...array, ...getAllChildCommodity(node)]
        array = array.filter((v, i) => array.indexOf(v) === i)
      })
    }
    return array
  }
  function getNodeById(nodes, id) {
    console.log({nodes})
    if (nodes.id === id) {
      return nodes
    } else if (Array.isArray(nodes.children)) {
      let result = null
      nodes.children.forEach((node) => {
        if (!!getNodeById(node, id)) {
          result = getNodeById(node, id)
        }
      })
      return result
    }

    return null
  }

  return getAllChildCommodity(getNodeById(node, id))
}
function getOnChange(checked, nodes,length) {
  const allNode = getChildById(commodityData, nodes.id)
  let array = checked
  ? [...selected, ...allNode]
  : selected.filter((value) => !allNode.includes(value))
  array = array.filter((v, i) => {
    return array.indexOf(v) === i
  })
  if(nodes.id != 'cg_top' && !checked){
    array = array.filter(el => !(el=='cg_top'))
  }
  if(!(array.includes('cg_top'))){
    if(array.length == length){
      array.push('cg_top')
    }
  }
  setSelected(array)
  
}
const renderCommodityTree = (nodes,length) => (
  <TreeItem
    key={nodes.id}
    nodeId={nodes.id}
    label={
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={selected.some((item) => item === nodes.id)}
            onChange={(event) =>{getOnChange(event.currentTarget.checked, nodes,length)}
            }
            onClick={(e) => e.stopPropagation()}
            />
        }
        label={<>{nodes.name}</>}
        key={nodes.id}
      />
    }
    >
    {Array.isArray(nodes.children)
      ? nodes.children.map((node) => renderCommodityTree(node,nodes.children.length))
      : null}
  </TreeItem>
)
const shipperData = {
  id: "shipper_top",
  name: "Shipper",
  children: planningDataShippers.map((item) => {

    const obj = {

      id: item.company_location_id,

      name: item.location_name,

      children: [
        {
          id: item.company_location_id+"_"+"COMMODITY",
          name: "Commodity Group",
          children: planningDataCg.map(cg_item=>{
            return {
              id: item.company_location_id+"_"+"COMMODITY"+"-" +cg_item.code,
              name: cg_item.description,
            }
          })
        }

      ]
    }
      return obj;

  }),
}
function getShipperChildById(node, id) {
  let array = []

  function getAllChildShipper(nodes) {
    if (nodes === null) return []
    array.push(nodes.id)
    if (Array.isArray(nodes.children)) {
      nodes.children.forEach((node) => {
        array = [...array, ...getAllChildShipper(node)]
        array = array.filter((v, i) => array.indexOf(v) === i)
      })
    }
    return array
  }

  function getNodeById(nodes, id) {
    if (nodes.id === id) {
      return nodes
    } else if (Array.isArray(nodes.children)) {
      let result = null
      nodes.children.forEach((node) => {
        if (!!getNodeById(node, id)) {
          result = getNodeById(node, id)
        }
      })
      return result
    }

    return null
  }

  return getAllChildShipper(getNodeById(node, id))
}
function getOnChangeShipper(checked, nodes, length) {
  let commodityParent;
  let shipperChild;

  const allNode = getShipperChildById(shipperData, nodes.id)
  let array = checked
    ? [...selectedShippers, ...allNode]
    : selectedShippers.filter((value) => !allNode.includes(value))
  array = array.filter((v, i) => array.indexOf(v) === i)
  commodityParent = nodes.id.split("-")[0]
  shipperChild = commodityParent.split("_")[0]
  
  if(nodes.id != shipperChild && !checked){
    array = array.filter(el => !(el == shipperChild))
    if(nodes.id != commodityParent && !checked){
      array = array.filter(el => !(el == commodityParent))
    }
  }
  let newArr = [] 
  array.map(el => {
    if(el.includes(commodityParent + '-')){
      newArr.push(el)
    }
  })
  if(newArr?.length == length){
    array = [...array,commodityParent,shipperChild]
  }
  if(array.includes(commodityParent)){
    array.push(shipperChild)
  }
    setSelectedShippers(array)
}
const renderShipperTree = (nodes, length) => (
  <TreeItem
    key={nodes.id}
    nodeId={nodes.id}
    label={
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={selectedShippers.some((item) => item === nodes.id)}
            onChange={(event) =>
              getOnChangeShipper(event.currentTarget.checked, nodes, length)
            }
            onClick={(e) => e.stopPropagation()}
          />
        }
        label={<>{nodes.name}</>}
        key={nodes.id}
      />
    }>
      {Array.isArray(nodes.children)
      ? nodes.children.map((node) => renderShipperTree(node, nodes.children.length))
      : null}
  </TreeItem>
)
  const updatePlanningProfile = async () => {

    if (!planningprofileName) {
      NotificationManager.error("Enter Planning Profile Name", "Error");
    }
    else {
      // let cg_to_save = selected.filter(x => x.includes("cgel_"));
      // cg_to_save = cg_to_save.map(x => x.replace("cgel_", ""));
      let cg_to_save = selected;
      // cg_to_save = cg_to_save.map(x => x.replace("cgel_", ""));
        let planningprofile_json = {
          "terminal_id": terminal_id,
          "commodity_groups": cg_to_save,
          "shippers": selectedShippers
        }
        let request_data = {
          "id": editplannigdata[0].id,
          "name": planningprofileName,
          "terminal_id": terminal_id,
          "planningprofile_json": JSON.stringify(planningprofile_json),
          "created_by": planningdata.userId,
          "is_default_planner":editeddefaultplanner
        }
        const userService = new UserService();
        let response = await userService.updateplanningprofile(request_data);
        console.log("responseafetrsave", response)
        if (response.data.data[0]) {
          // setPlanningProfile(refreshkendotable)
          setOpenConfirmationModal(false);
          NotificationManager.success("Planning profile updated successfully", "success");
          const allPlannerProfiles = new UserService();
          allPlannerProfiles.getAllPlanningProfiles().then(res => setPlanningProfile(res));
          console.log("hellllll")

          setPlanningDataCg([]);
          setPlanningDataShippers([]);
          setPlanningprofileName([]);
          setExpanded([]);
          setSelected([]);
          setSelectedShippers([]);

        }
    }
  }
  const editplanningprofile = async (data) => {

    const userService = new UserService();
    const getresponse = await userService.getplanningprofilebyid(data.id);

    setPlanningprofileName(getresponse[0].name)

    const planningjs = JSON.parse(getresponse[0].planningprofile_json);
    // const selectedcommodity = planningjs?.commodity_groups.map((e) => `cgel_${e}`)
    const selectedcommodity = planningjs?.commodity_groups
    console.log("Shipper",planningjs.shippers)
    // const selectedshipper = planningjs?.shippers?.map((e) => e.commodity_groups.map((p) => `shipper_${e.name}_${p}`))
    const selectedshipper = [...new Set(planningjs?.shippers?.map((e) => e.split("_")[0]))]
    // const selectedshippercg = planningjs?.shippers.map((e) => e)
    // const childlevelselectedshipper = planningjs.shippers.map((e) => `shipperel_${e.name}`)
    const childlevelselectedshipper = planningjs?.shippers?.map((e) => e.split("-")[1])
    const allselectedshipper = []

    // for (let i = 0; i < selectedshippercg.length; i++) {
    //   for (let j = 0; j < selectedshippercg[i].length; j++) {
    //     allselectedshipper.push(selectedshippercg[i][j])
    //   }
    // }

    for (let p = 0; p < childlevelselectedshipper.length; p++) {
      allselectedshipper.push(childlevelselectedshipper[p])
    }
    for (let i = 0; i < selectedshipper.length; i++) {
      allselectedshipper.push("shipper_top", "shipper_cg_top")
      for (let j = 0; j < selectedshipper[i].length; j++) {
        allselectedshipper.push(selectedshipper[i][j])
      }
    }
    console.log({allselectedshipper})

    console.log({selectedshipper});

    // setSelected([...selectedcommodity, ...allselectedshipper]);
    setSelected([...selectedcommodity])
    setSelectedShippers(planningjs.shippers)
    setPlanningdata(getresponse);
    const response = await userService.planningProfileByTerminalId(data.terminal_id)
    const commodity_groups = response.data.data.commodity_groups;
    const shippers = response.data.data.shippers.slice(0, 100);
    setPlanningDataCg(commodity_groups);
    setPlanningDataShippers(shippers);

    setOpenConfirmationModal(true);
  };
  
  const deletePlanningprofileModalOpen = (data) => {
    setIsOpenDeleteModalForPF(true);
    setPlnningProfileDataForDelete(data);
  };
  const deletePlanningprofile = async (data) => {
    const userService = new UserService();
    if(data.is_default_planner){
    NotificationManager.error("Default planning profile cannot be deleted")
    }
    else{
   const response = await userService.deletePlanningProfileById(data.id);
    console.log("🚀 ~ file: planningProfileList.js ~ line 422 ~ deletePlanningprofile ~ response", response);
    
     if (response.status == 200) {
     
      NotificationManager.success('The Planning Profile Has Been Deleted', 'Success');
    const allPlannerProfiles = new UserService();
    allPlannerProfiles.getAllPlanningProfiles().then(res => setPlanningProfile(res));
      setIsOpenDeleteModalForPF(false);
    } else {
      NotificationManager.error(
        "This Planning Profile is Already Assigned to a User , Please Unassign Before Deleting.", Error
      );
    }
    }

   

   
  }
	const ActionPlanningProfile = (props) => {
		return (
			<>
				<td className="adjustbutton">
					<button
						type="button"
						class="btn_blue_smadjust btn-blue ml_10"
						onClick={() => editplanningprofile(props.dataItem)}>
						<i class="fa fa-pencil mr_5 del_icon" aria-hidden="true"></i>
						EDIT
					</button>
					<button
						type="button"
						class="btn_blue_smadjust btn-blue ml_10"
						onClick={() => {
							deletePlanningprofileModalOpen(props.dataItem)
						}}>
						<i class="fa fa-trash mr_5 del_icon" aria-hidden="true"></i>
						Delete
					</button>
				</td>
			</>
		)
	}
  const parentcallback=(data)=>{
    setPlanningProfile(data)
  }
	return (
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="card card_shadow">
        
						<div className="card-body ">
            <PlanningProfile parentcallback={parentcallback}/>
           
							<div className="table-responsive">
             
                {
                  planningProfile && planningProfile?.length > 0 ? (
                    <React.Fragment>
                      <Grid
                        filter={dataState.filter}
                        sort={dataState.sort}
                        sortable={true}
                        filterable={true}
                        pageable={{
                          pageSizes: [5, 10, 20, 25, 50, 100],
                          info: true,
                          previousNext: true,
                          buttonCount: 10
                        }}
                        resizable={true}
                        skip={dataState.skip}
                        take={dataState.take}
                        data={dataResult && dataResult}
                        onRowClick={(e) => props.parentcallback(true, e.dataItem)}
											onDataStateChange={dataStateChange}>
											<GridColumn title="Action" cell={ActionPlanningProfile} />
											<GridColumn field="name" title="Name" />
											<GridColumn
												field="terminal.terminal"
												title="Terminal"
											/>
                        <GridColumn
                    field="is_default_planner"
                    sortable={true}
                    cell={(e) => {
                      return (
                        <td>                      
                          <FormControlLabel 
                          control=
                          {

                          // <Checkbox color="black" checked={e.dataItem.is_default_planner ? true : false}  />
                          <div className="squareDisabled">
                          <input
                                  name="roleradio"
                                  type="checkbox"
                                  checked={e.dataItem.is_default_planner ? true : false}
                                  disabled
                                />
                                </div>
                        
                        }

                          labelPlacement="start" />
                        </td>
                      );
                    }}
                    title="Default Planning Profile"
                    width="200px"
                    filterable={true}
                    filter={"boolean"}
                  />

                     
                      </Grid>
                    </React.Fragment>
                  )
                    : isLoading ? (
                      <div>
                        <ProgressBar animated now={100} />
                      </div>
                    ) : (
                      <div className="text-center">No Data Found</div>
                    )
                }
              </div>
            </div>
            <NotificationContainer />
          </div>
        </div>
      </div>
     
      <Modal
        show={openConfirmationModal}
        onHide={() => setOpenConfirmationModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Planning profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="planning_profile_popup_continue">
            <button
              type="button"
              className="btn_blue btn-blue ml_10" onClick={updatePlanningProfile} >
              Save
            </button>
          </div>

          <label for="exampleFormControlInput1">Terminal</label>
          <div className="meterial_autocomplete">
            <Autocomplete
              disablePortals
              id="terminals"
              options={allTerminal}
              defaultValue={{ full_terminal_name: editplannigdata[0]?.terminal?.terminal }}
              getOptionLabel={(options) => `${options.full_terminal_name}`}
              sx={{ width: 300 }}
              onChange={handleChange}
              disabled
              renderInput={(params) => <TextField {...params} variant="outlined"
              fullWidth={true} placeholder="Select a Terminal" />}
            />
          </div>
          {/* <div className="planning_profile_popup_continue">
                        <Button onClick={() => populateAccessTree(terminal_id)}>Continue</Button>
                    </div> */}


          <label for="exampleFormControlInput1">Planning Profile Name</label>

          <div className="planning_profile_popup_textbox">
            <div className="meterial_autocomplete">
              <TextField id="outlined-basic"
                placeholder="Enter Planning Profile Name"
                variant="outlined"
                defaultValue={editplannigdata[0]?.name}
                value={planningprofileName}
                onChange={handleProfileNameChange}
              />
            </div>
          </div>
          <div className="mt_10 ml_5 ">
           <label for="defaultplanner">Default Planning Profile</label>
         <input type="checkbox" className='ml_6' defaultChecked={editplannigdata[0]?.is_default_planner? true : false} 
          onChange={(e) => defaultplannerHandlerForEdit(e)}
                            />
                        </div>



          <div className="plnning_treeview_section">
          <>
                {planningDataCg && planningDataCg.length > 0 ? (
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                    >
                        {renderCommodityTree(commodityData)}
                    </TreeView>
                ) : null }
                {planningDataShippers && planningDataShippers.length > 0 ? (
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}>
                        {renderShipperTree(shipperData)}
                    </TreeView>
                ) : null }
			</>
          </div>
        </Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
			<Modal
				show={isOpenDeleteModalForPF}
				onHide={() => setIsOpenDeleteModalForPF(false)}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						Delete Planning profile
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Are you sure to delete?</p>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn_blue btn-blue ml_10" onClick={()=>{deletePlanningprofile(planningProfileDataForDelete)}}>
						Delete
					</button>
					<button
						type="button"
						className="btn_blue btn-blue ml_10"
						onClick={() => setIsOpenDeleteModalForPF(false)}>
						Cancel
					</button>
				</Modal.Footer>
			</Modal>
		</>

  )
}

export default PlanningProfileList
