import { useEffect, useState, useContext } from "react";
import UserService from "../../services/userService";

import Userfiltertable from "../../components/userfiltertable/userfiltertable";

import { ContextData } from "../../components/appsession";
import TerminalService from "../../services/terminalService";
import AppFilterService from "../../services/appFilterService";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";import TrailerService from "../../services/trailerService";

import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { makeStyles } from "@material-ui/core/styles";
import set from "date-fns/set/index.js";
import ProgressBar from "react-bootstrap/ProgressBar";

const Planningtab = (props) => {
  const { profileById } = props
  console.log("profileById", profileById)
  const [assignedDriversList, setassignedDriversList] = useState([])
  const [assignTrailerList, setassignTrailerList] = useState([]);
  const [isDefaultPlannerChecked, setIsDefaultPlannerChecked] = useState([]);
  const [assignedDriversToUpdate, setAssignedDriversToUpdate] = useState([])
  const [assigncheckeddriver,setassigncheckeddriver]=useState([])

  const [isLoading, setIsLoading] = useState(false);

  console.log("assignedDriversList", assignedDriversList)

  const useStyles = makeStyles((theme) => ({
    statuscolor: {
      fontWeight: "bold",
      textAlign: "center !important",
      fontSize: 15,
    },
  }));
  const classes = useStyles();
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [
       
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });
  /// assign driver kendotable
  const [dataResult, setDataResult] = useState(process(assignedDriversList, dataState));

  const dataStateChange = (event) => {
    setDataResult(process(assignedDriversList, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {
    setDataResult(process(assignedDriversList, dataState));
  }, [assignedDriversList]);

  const parentcallback=(data)=>{
    setassigncheckeddriver(data)
  }

  useEffect(async () => {
    setIsLoading(true);
    const terminalService = new TerminalService()
    const assigndriver_planningprofile = await terminalService.getAssigneddriversbyplanningprofile(
      profileById.id

    );
    setassignedDriversList(assigndriver_planningprofile)
    let allcheckedarr = assigndriver_planningprofile.filter((x )=> x.planner).map((it)=>it.drivers_id)
    console.log("data6",allcheckedarr)
    setassigncheckeddriver(allcheckedarr) 
    setIsLoading(false);
  }, [])
  const updateUserData = async() => {
    const userService = new UserService();
    try {
      let requestBody = {
        "planning_profile_id": profileById.id,
        "terminal_id": profileById.terminal_id,

        "drivers": assigncheckeddriver
      }
      const UserDriverResponse = await userService.updateAssigneddriversforplanningprofile(requestBody)
      const terminalService = new TerminalService()
      const assigndriver_planningprofile = await terminalService.getAssigneddriversbyplanningprofile(
        profileById.id

      );
      setassignedDriversList(assigndriver_planningprofile)

      NotificationManager.success("Assigned planning driver updated", "Success");

    }
    catch (error) {
      return NotificationManager.error("You can not uncheck Assign Driver","Error");
    }  
  };
  console.log("data5",assigncheckeddriver)
  const handleIsDefaultPlannerCheckbox = (event, data) => {
   
   // event.preventDefault()
   // let allcheckedarr= assignedDriversList.filter((x )=> x.planner).map((it)=>it.drivers_id)
    //console.log("data2",allcheckedarr)
    if(event.target.checked){
      if(!assigncheckeddriver.includes(data)){
        // allcheckedarr.push(data)
        //console.log("data3",allcheckedarr)
        setassigncheckeddriver([...assigncheckeddriver,data])
      }
    }
    else{
      let modifiedchecked=assigncheckeddriver.filter((it)=>it!==data)
      console.log("data4",modifiedchecked)
      setassigncheckeddriver(modifiedchecked)
    }   
    
  };
  /// end asssign driverlist

  ///assigntrailer list//
  const [dataState1, setDataState1] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [
        {
          field: "is_active",
          operator: "eq",
          value: true,
        },
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });


  const [dataResult1, setDataResult1] = useState(process(assignTrailerList, dataState1));

  const dataStateChange1 = (event) => {
    setDataResult1(process(assignTrailerList, event.dataState));
    setDataState1(event.dataState1);
  };

  useEffect(() => {
    setDataResult1(process(assignTrailerList, dataState1));
  }, [assignTrailerList]);
  useEffect(async () => {
    setIsLoading(true);
    const trailerService = new TrailerService();
    const allterminaltrailers = await trailerService.getTrailersByTerminalId(
      profileById.terminal_id

    );

    setassignTrailerList(allterminaltrailers);
    setIsLoading(false);
  }, []);

  

/// end assign trailer list
  return (
    <div className="row special_row_flex">
      <div className="col-xl-50">
        <div className="card card_shadow">
          <div className="card-body special_card_padding">
          <div class="access_header_planning">
            <h2 class="header-title">Assign Planning Drivers</h2>
            <button
              type="button"
              className="btn_blue_sm btn-blue ml_10"
              onClick={(e) => updateUserData()}
              // disabled={accessDisabled ? true : false} 
              // style={{background : accessDisabled ? "#dddddd": ""}}
            >
              SAVE
            </button>           
             </div>
        <div className="table-responsive">
          {assignedDriversList?.length > 0 ? (
            <Grid
              filter={dataState.filter}
              sort={dataState.sort}
              sortable={true}
              filterable={true}
              // filterOperators={filterOperators}
              pageable={{
                pageSizes: [5, 10, 20, 25, 50, 100],
                info: true,
                previousNext: true,
                buttonCount: 10
              }}
              resizable={true}
              skip={dataState.skip}
              take={dataState.take}
              data={dataResult}
              onDataStateChange={dataStateChange}
            // onRowClick={(e) => props.parentCallBackForTerminal(true, e.dataItem)}
            >
           
            
              <GridColumn
                field="driver_id"
                title="Driver ID"
                filterable={true}
                cell={(e) => {
                  return (
                    <td>

                      {e.dataItem.drivers_id ? e.dataItem.drivers_id : ""}

                    </td>
                  );
                }}
              />
             
            
             
              <GridColumn
                title="Terminal"
                field="terminal"
                filterable={true}
                cell={(e) => {

                  return (
                    <td>
                      {e.dataItem.terminal ? e.dataItem.terminal : ""}
                    </td>


                  );
                }}
              />
               
               <GridColumn
                    field="planner"
                    title="Planners"
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          <div className="square1">
                          <input
                          id={e.dataItem.drivers_id +"checkbox"}
                         
                          type="checkbox"
                          onClick={(event) => handleIsDefaultPlannerCheckbox(event, e.dataItem.drivers_id)}
                          defaultChecked={e.dataItem.planner}
                          disabled={e.dataItem.planner}
                        />
                        </div>
                        </td>
                      );
                    }}
                  />
                         

            </Grid>

          ) : isLoading ? (
            <div>
              <ProgressBar animated now={100} />
              <div className="middle loader--text1"> </div>
            </div>
          ) : (
            <div className="text-center">No data found</div>

          )}
        </div>
        <NotificationContainer />
        </div>
       
        </div>
      </div>
      <div className="col-xl-50">
      <div className="card card_shadow">
          <div className="card-body special_card_padding">
          <div class="access_header_planning">
            <h2 class="header-title">Assign Planning Trailers</h2>
            {/* <button type="button" class="btn_blue_sm btn-blue ml_10">SAVE</button> */}
            </div>
        <div className="table-responsive">
          {assignTrailerList?.length > 0 ? (
            <Grid
              filter={dataState1.filter}
              sort={dataState1.sort}
              sortable={true}
              filterable={true}
              //filterOperators={filterOperators}
              pageable={{
                pageSizes: [5, 10, 20, 25, 50, 100],
                info: true,
                previousNext: true,
                buttonCount: 10
              }}
              resizable={true}
              skip={dataState1.skip}
              take={dataState1.take}
              data={dataResult1}
              onDataStateChange={dataStateChange1}
            //onRowClick={(e) => props.parentcallback(true, e.dataItem)}
            >
             
            
              <GridColumn
                field="trailer_id"
                title="Trailer Id"
                width="200px"
                filterable={true}
                cell={(e) => {
                  return <td>{e.dataItem.trailer_id}</td>;
                }}
              />
             
             <GridColumn
                field="commoditygroup_full_name"
                title="Commoditygroup"
                width="200px"
                filterable={true}
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.commoditygroup_full_name
                        ? e.dataItem.commoditygroup_full_name
                        : ""}
                    </td>
                  );
                }}
              />
            
            
             
              <GridColumn
                field="terminal_full_name"
                title="Terminal"
                width="200px"
                filterable={true}
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.terminal_full_name
                        ? e.dataItem.terminal_full_name
                        : ""}
                    </td>
                  );
                }}
              />

           

             
               <GridColumn
                field="is_active"
                sortable={true}
                cell={(e) => {
                  return (
                    <td
                      className={classes.statuscolor}
                      style={{
                        color: e.dataItem.is_active ? "#259125" : "#FF0000",
                      }}
                    >
                      {e.dataItem.is_active ? "True" : "False"}
                    </td>
                  );
                }}
                title="Is Active"
                width="150px"
                filterable={true}
                filter={"boolean"}
              />
            </Grid>
          ) : isLoading ? (
            <div>
              <ProgressBar animated now={100} />
              <div className="middle loader--text1"> </div>
            </div>
          ) : (
            <div className="text-center">No data found</div>

          )}
        </div>
        </div>
        </div>
      </div>

    </div>
  );
};

export default Planningtab;
