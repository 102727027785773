import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500,
  },
  customTooltip: {
    maxWidth: 'none',
    backgroundColor: "#4267B2",
    borderColor: "#2C4F95",
    borderStyle: "solid",
    borderWidth: "2px",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.54)",
    padding: "12px",
    borderRadius: "10px"
  },
  customArrow: {
    color: "#4267B2",
    fontSize: "20px"
  },
}));

const formatDate = (epoch_date, tzone) => {
  return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
}

const PBTooltip = (props) => {
  const onClickHandler = 'onClick' in props ? props.onClick : () => {};
  const classes = useStyles();

    return (
      <Tooltip title={
        <React.Fragment>
          <Typography color="inherit">
            {props.tooltip}
          </Typography>
        </React.Fragment>
      }
        onClick={onClickHandler}
        placement="top"
        classes={{
          tooltip: classes.customTooltip,
          arrow: classes.customArrow,
        }}
        arrow
      >
        {props.children}
      </Tooltip>
    );
}

const DriverTooltip = (props) => {
  const it = props.it;

  return (
    <span className="tooltip-adjust">
      <div className="main_tooltip_section1">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Customer: </div>
            <div className="tooltip_text_right">  {it.customer_name}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Order: </div>
            <div className="tooltip_text_right">  {it.order_type_id}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Hold: </div>
            <div className="tooltip_text_right">   {it.on_hold ? it.on_hold : "No Data"}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Type: </div>
            <div className="tooltip_text_right">  </div>
          </div>
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Terminal: </div>
            <div className="tooltip_text_right">   {it.terminalId} - {it.terminal_city ? it.terminal_city : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Status: </div>
            <div className="tooltip_text_right"> {it.order_status}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Reason: </div>
            <div className="tooltip_text_right"> {"No Data"}</div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">BOL: </div>
            <div className="tooltip_text_right">  {it.blnum}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Consignee Ref#: </div>
            <div className="tooltip_text_right"> {it.consignee_refno}</div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left fs_20">Shipper: </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_right">
              <p className="tooltip_text_right">
                {it.company_location_id ?
                  (`${it.company_location_id} - ${it.location_name}`)
                  : "No Data"
                }
                <br />
                {it.address1 ?
                  (`${it.address1}`)
                  : null
                }
                <br />
                {it.city ?
                  (`${it.city}, ${it.state}, ${it.zip} `)
                  : null
                }
              </p>
            </div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left fs_20">Consignee: </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_right">
              <p className="tooltip_text_right">
                {it.company_location_id1 ?
                  (`${it.company_location_id1} - ${it.location_name1}`)
                  : "No Data"
                }
                <br />
                {it.address11 ?
                  (`${it.address11}`)
                  : null
                }
                <br />
                {it.city1 ?
                  (`${it.city1}, ${it.state1}, ${it.zip1} `)
                  : null
                }
              </p>
            </div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap1">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Driver Load : </div>
            <div className="tooltip_text_right">   {it.pickup_driver_load_unload ? it.pickup_driver_load_unload : "No Data"}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Driver Unload: </div>
            <div className="tooltip_text_right">    {it.delivery_driver_load_unload ? it.delivery_driver_load_unload : "No Data"}</div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Early Pickup Appt:  </div>
            <div className="tooltip_text_right">     {formatDate(it.pickup_sched_arrive_early_utc, it.pickup_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Late Pickup Appt:</div>
            <div className="tooltip_text_right">    {formatDate(it.pickup_sched_arrive_late_utc, it.pickup_timezone)}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Appt Rq'd: </div>
            <div className="tooltip_text_right"> {it.pickup_appt_required ? it.pickup_appt_required : "No Data"} </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Appt Confirmed:</div>
            <div className="tooltip_text_right">{it.picup_confirmed ? it.picup_confirmed : "No Data"}  </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">D- </div>
            <div className="tooltip_text_right">   {it.driverid}</div>
          </div>
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Early Delivery Appt: </div>
            <div className="tooltip_text_right">   {formatDate(it.delivery_sched_arrive_early_utc, it.delivery_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Late Delivery Appt:</div>
            <div className="tooltip_text_right">   {formatDate(it.delivery_sched_arrive_late_utc, it.delivery_timezone)}</div>
          </div>
        </div>
        <div className="tooltip_left_4">

          <div className="tooltip_single_sec ">
            <div className="tooltip_text_left">Appt Rq'd: </div>
            <div className="tooltip_text_right"> {"No Data"} </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Appt Confirmed:</div>
            <div className="tooltip_text_right"> {"No Data"} </div>
          </div>
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Planning Comment:</div>
            <div className="tooltip_text_right">   {"No Data"}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 ">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Commodity Group:</div>
            <div className="tooltip_text_right">
              {it.commoditygroupid || it.commoditygroup_description ?
                `${it.commoditygroupid}- ${it.commoditygroup_description}` : "No Data"
              }
            </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Product:</div>
            <div className="tooltip_text_right">   {it.commodity_desc}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Commodity:</div>
            <div className="tooltip_text_right">  {it.commodity_Id} - {it.commodity}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Ordered Weight: </div>
            <div className="tooltip_text_right">   {it.ordered_wt}</div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
    </span>
  );
}

const NonDriverTooltip = (props) => {
  const it = props.it;

  return (
    <span className="tooltip-adjust">
      <div className="main_tooltip_section1">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Customer: </div>
            <div className="tooltip_text_right">  {it.customer_name}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Order: </div>
            <div className="tooltip_text_right">  {it.orderid}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Hold: </div>
            <div className="tooltip_text_right">   {it.on_hold ? it.on_hold : "No Data"}</div>
          </div>

        </div>

        <div className="tooltip_left_2">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Type: </div>
            <div className="tooltip_text_right">  </div>
          </div>

        </div>

        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Terminal: </div>
            <div className="tooltip_text_right">   {it.terminalId} - {it.terminal_city ? it.terminal_city : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Status: </div>
            <div className="tooltip_text_right"> {it.order_status}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Reason: </div>
            <div className="tooltip_text_right"> {"No Data"}</div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">BOL: </div>
            <div className="tooltip_text_right">  {it.blnum}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Consignee Ref#: </div>
            <div className="tooltip_text_right"> {it.consignee_refno}</div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left fs_20">Shipper: </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_right">
              <p className="tooltip_text_right">
                {it.company_location_id ?
                  (`${it.company_location_id} - ${it.location_name}`)
                  : "No Data"
                }
                <br />
                {it.address1 ?
                  (`${it.address1}`)
                  : null
                }
                <br />
                {it.city ?
                  (`${it.city}, ${it.state}, ${it.zip} `)
                  : null
                }
              </p>
            </div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left fs_20">Consignee: </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_right">
              <p className="tooltip_text_right">
                {it.company_location_id1 ?
                  (`${it.company_location_id1} - ${it.location_name1}`)
                  : "No Data"
                }
                <br />
                {it.address11 ?
                  (`${it.address11}`)
                  : null
                }
                <br />
                {it.city1 ?
                  (`${it.city1}, ${it.state1}, ${it.zip1} `)
                  : null
                }
              </p>
            </div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap1">
        <div className="tooltip_left_1">

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Driver Load : </div>
            <div className="tooltip_text_right">   {it.pickup_driver_load_unload ? it.pickup_driver_load_unload : "No Data"}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Driver Unload: </div>
            <div className="tooltip_text_right">    {it.delivery_driver_load_unload ? it.delivery_driver_load_unload : "No Data"}</div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Early Pickup Appt:  </div>
            <div className="tooltip_text_right">     {formatDate(it.pickup_sched_arrive_early_utc, it.pickup_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Late Pickup Appt:</div>
            <div className="tooltip_text_right">    {formatDate(it.pickup_sched_arrive_late_utc, it.pickup_timezone)}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Appt Rq'd: </div>
            <div className="tooltip_text_right"> {it.pickup_appt_required ? it.pickup_appt_required : "No Data"} </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Appt Confirmed:</div>
            <div className="tooltip_text_right">{it.picup_confirmed ? it.picup_confirmed : "No Data"}  </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">T- </div>
            <div className="tooltip_text_right">   {it.trailerid}</div>
          </div>
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Early Delivery Appt: </div>
            <div className="tooltip_text_right">   {formatDate(it.delivery_sched_arrive_early_utc, it.delivery_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Late Delivery Appt:</div>
            <div className="tooltip_text_right">   {formatDate(it.delivery_sched_arrive_late_utc, it.delivery_timezone)}</div>
          </div>
        </div>
        <div className="tooltip_left_4">
          <div className="tooltip_single_sec ">
            <div className="tooltip_text_left">Appt Rq'd: </div>
            <div className="tooltip_text_right"> {"No Data"} </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Appt Confirmed:</div>
            <div className="tooltip_text_right"> {"No Data"} </div>
          </div>
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Planning Comment:</div>
            <div className="tooltip_text_right">   {"No Data"}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 ">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Commodity Group:</div>
            <div className="tooltip_text_right">
              {it.commoditygroupid || it.commoditygroup_description ?
                `${it.commoditygroupid}- ${it.commoditygroup_description}` : "No Data"
              }
            </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Product:</div>
            <div className="tooltip_text_right">   {it.commodity_desc}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Commodity:</div>
            <div className="tooltip_text_right">  {it.commodity_Id} - {it.commodity}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Ordered Weight: </div>
            <div className="tooltip_text_right">   {it.ordered_wt}</div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
    </span>
  );
}

const NestedPBToolTip = (props) => {
  const it = props.it;

  return (
    <span className="tooltip-adjust">
      <div className="main_tooltip_section1">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Customer: </div>
            <div className="tooltip_text_right">  {it.customer_name}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Order: </div>
            <div className="tooltip_text_right">  {it.orderid}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Hold: </div>
            <div className="tooltip_text_right">   {it.on_hold ? it.on_hold : "No Data"}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Type: </div>
            <div className="tooltip_text_right">  </div>
          </div>
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Terminal: </div>
            <div className="tooltip_text_right">   {it.terminalId} - {it.terminal_city ? it.terminal_city : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Status: </div>
            <div className="tooltip_text_right"> {it.order_status}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Reason: </div>
            <div className="tooltip_text_right"> {"No Data"}</div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">BOL: </div>
            <div className="tooltip_text_right">  {it.blnum}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Consignee Ref#: </div>
            <div className="tooltip_text_right"> {it.consignee_refno}</div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left fs_20">Shipper: </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_right">
              <p className="tooltip_text_right">
                {it.company_location_id ?
                  (`${it.company_location_id} - ${it.location_name}`)
                  : "No Data"
                }
                <br />
                {it.address1 ?
                  (`${it.address1}`)
                  : null
                }
                <br />
                {it.city ?
                  (`${it.city}, ${it.state}, ${it.zip} `)
                  : null
                }
              </p>
            </div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left fs_20">Consignee: </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_right">
              <p className="tooltip_text_right">

                {it.company_location_id1 ?
                  (`${it.company_location_id1} - ${it.location_name1}`)
                  : "No Data"
                }
                <br />
                {it.address11 ?
                  (`${it.address11}`)
                  : null
                }
                <br />
                {it.city1 ?
                  (`${it.city1}, ${it.state1}, ${it.zip1} `)
                  : null
                }
              </p>
            </div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap1">
        <div className="tooltip_left_1">

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Driver Load : </div>
            <div className="tooltip_text_right">   {it.pickup_driver_load_unload ? it.pickup_driver_load_unload : "No Data"}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Driver Unload: </div>
            <div className="tooltip_text_right">    {it.delivery_driver_load_unload ? it.delivery_driver_load_unload : "No Data"}</div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Early Pickup Appt:  </div>
            <div className="tooltip_text_right">     {formatDate(it.pickup_sched_arrive_early_utc, it.pickup_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Late Pickup Appt:</div>
            <div className="tooltip_text_right">    {formatDate(it.pickup_sched_arrive_late_utc, it.pickup_timezone)}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Appt Rq'd: </div>
            <div className="tooltip_text_right"> {it.pickup_appt_required ? it.pickup_appt_required : "No Data"} </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Appt Confirmed:</div>
            <div className="tooltip_text_right">{it.picup_confirmed ? it.picup_confirmed : "No Data"}  </div>
          </div>
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Early Delivery Appt: </div>
            <div className="tooltip_text_right">   {formatDate(it.delivery_sched_arrive_early_utc, it.delivery_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Late Delivery Appt:</div>
            <div className="tooltip_text_right">   {formatDate(it.delivery_sched_arrive_late_utc, it.delivery_timezone)}</div>
          </div>
        </div>
        <div className="tooltip_left_4">
          <div className="tooltip_single_sec ">
            <div className="tooltip_text_left">Appt Rq'd: </div>
            <div className="tooltip_text_right"> {"No Data"} </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Appt Confirmed:</div>
            <div className="tooltip_text_right"> {"No Data"} </div>
          </div>
        </div>
      </div>
      <div className="main_tooltip_section1 tooltip_inner_gap">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Planning Comment:</div>
            <div className="tooltip_text_right">   {"No Data"}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
      <div className="main_tooltip_section1 ">
        <div className="tooltip_left_1">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Commodity Group:</div>
            <div className="tooltip_text_right">
              {it.commoditygroupid || it.commoditygroup_description ?
                `${it.commoditygroupid}- ${it.commoditygroup_description}` : "No Data"
              }
            </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Product:</div>
            <div className="tooltip_text_right">   {it.commodity_desc}</div>
          </div>
        </div>
        <div className="tooltip_left_2">
        </div>
        <div className="tooltip_left_3">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Commodity:</div>
            <div className="tooltip_text_right">  {it.commodity_Id} - {it.commodity}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Ordered Weight: </div>
            <div className="tooltip_text_right">   {it.ordered_wt}</div>
          </div>
        </div>
        <div className="tooltip_left_4">
        </div>
      </div>
    </span>
  );
}

const TrailerPBToolTip = (props) => {
  const it = props.it;

  return (
    <span className="tooltip-adjust">
      <div className="main_tooltip_section">
        <div className="tooltip_left">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Trailer : </div>
            <div className="tooltip_text_right">{it.trailerid ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Type : </div>
            <div className="tooltip_text_right">{it.equip_types ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Commodity Group : </div>
            <div className="tooltip_text_right">{it.commoditygroupid}{it.commoditygroup_description} </div>
          </div>
          <div className="tooltip_single_sec trailer_gap">
            <div className="tooltip_text_left">Last Order : </div>
            <div className="tooltip_text_right"></div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Last Commodity : </div>
            <div className="tooltip_text_right"></div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Last Product : </div>
            <div className="tooltip_text_right"></div>
          </div>
          <div className="tooltip_single_sec trailer_gap">
            <div className="tooltip_text_left">Loads since last wash : </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Days since last wash : </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Last Wash Date : </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Last Wash W/O : </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec trailer_gap">
            <div className="tooltip_text_left">License : </div>
            <div className="tooltip_text_right">{it.license_plates ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">State : </div>
            <div className="tooltip_text_right">{it.state ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Year : </div>
            <div className="tooltip_text_right">{it.model_year ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Make : </div>
            <div className="tooltip_text_right">{it.makes ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Model : </div>
            <div className="tooltip_text_right">{it.models ?? "No Data"}</div>
          </div>
        </div>
        <div className="tooltip_right">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Status: </div>
            <div className="tooltip_text_right">
              {it.stat === "A" ? "Active" :
                it.stat === "S" ? "Sold" :
                  it.stat === "I" ? "Inactive" :
                    it.stat === "T" ? "Sale-pending" :
                      it.stat === "O" ? "On-order" :
                        it.stat === "C" ? "Collision" :
                          it.stat === "In shop" ? "In shop" : "No Data"

              }
            </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Terminal : </div>
            <div className="tooltip_text_right">{it.terminalId} - {it.terminal_city}</div>
          </div>
          <br />
          <div className="tooltip_single_sec trailer_gap">
            <div className="tooltip_text_left">Shipper Pool : </div>
            <div className="tooltip_text_right">{it.shipper_pool ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Dedicated : </div>
            <div className="tooltip_text_right">{it.dedicated ?? "No Data"}</div>
          </div>
          <div className="tooltip_single_sec trailer_gap1">
            <div className="tooltip_text_left">Loads to next wash: </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Day to Next wash: </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Next Wash Date: </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">PM Due Date: </div>
            <div className="tooltip_text_right">
              {it?.pm_due_date_utc != null ? convertDateTime(it?.pm_due_date_utc) : "No Data"}
            </div>
          </div>
          <div className="tooltip_single_sec trailer_gap">
            <div className="tooltip_text_left">Length: </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Width: </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Height: </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Volume: </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Tare Weight: </div>
            <div className="tooltip_text_right">{it.tare_weight ?? "No Data"}</div>
          </div>
        </div>
      </div>
    </span>
  );
}

const SamsaraPBToolTip = (props) => {
  const it = props.it;

  return (
    <span className="tooltip-adjust">
      <div className="main_tooltip_section">
        <div className="tooltip_left_driver">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Driver : </div>
            <div className="tooltip_text_right">{it.driverid} - {it.driver_name ? it.driver_name : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Terminal : </div>
            <div className="tooltip_text_right">{it.terminalId} - {it.terminal_city ? it.terminal_city : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec pt_20">
            <div className="driver_special_text">Samsara Data</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Vehicle : </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Duty Status : </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Drive Remaining : </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Shift Remaining : </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Cycle Remaining : </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
        </div>
        <div className="tooltip_middle_driver">
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Time in Status : </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
        </div>
        <div className="tooltip_rightPu">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Type : </div>
            <div className="tooltip_text_right">{it.drivertypeclass ? it.drivertypeclass : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Hired: </div>
            <div className="tooltip_text_right">{it.Hiredate ? it.Hiredate : "No Data"}</div>
          </div>
        </div>
      </div>
    </span>
  );
}

const OrderPBToolTip = (props) => {
  const it = props.it;

  return (
    <span className="tooltip-adjust">
      <div className="main_tooltip_section">
        <div className="tooltip_left">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Order: </div>
            <div className="tooltip_text_right">{it.orderid}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Move: </div>
            <div className="tooltip_text_right">{it.move_id}</div>
          </div>
        </div>
        <div className="tooltip_middle">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">  Type:</div>
            <div className="tooltip_text_right">  {it.order_type_id}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">  Type:</div>
            <div className="tooltip_text_right">  {it.movement_type}</div>
          </div>
        </div>
        <div className="tooltip_rightPu">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Status:</div>
            <div className="tooltip_text_right"> {it.order_status}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Status:</div>
            <div className="tooltip_text_right"> {it.movement_status}</div>
          </div>
        </div>
      </div>
      <div className="main_tooltip_section tooltip_inner_gap">
        <div className="tooltip_left">
          <div className="tooltip_single_sec  ">
            <div className="tooltip_text_left fs_20">{it.stop_pickup__type ? it.stop_pickup__type.toUpperCase() : "No Data"} : </div>
          </div>
          <div className="tooltip_single_sec">
            <p className="tooltip_text_right">
              {it.company_location_id ?
                (`${it.company_location_id} - ${it.location_name}`)
                : "No Data"
              }
              <br />
              {it.address1 ?
                (`${it.address1}`)
                : null
              }

              <br />
              {it.city ?
                (`${it.city}, ${it.state}, ${it.zip} `)
                : null
              }
            </p>
          </div>
        </div>
        <div className="tooltip_middle">

        </div>
      </div>

      <div className="main_tooltip_section tooltip_inner_gap1">
        <div className="tooltip_left">



          <div className="tooltip_single_sec ">
            <div className="tooltip_text_left">Early Pickup Appt: </div>
            <div className="tooltip_text_right">  {formatDate(it.pickup_sched_arrive_early_utc, it.pickup_timezone)}</div>
          </div>
          <div className="tooltip_single_sec ">
            <div className="tooltip_text_left">Late Pickup Appt: </div>
            <div className="tooltip_text_right">  {formatDate(it.pickup_sched_arrive_late_utc, it.pickup_timezone)}</div>
          </div>
        </div>
        <div className="tooltip_middle">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">  Appt Rq'd:</div>
            <div className="tooltip_text_right">  {it.pickup_appt_required ? it.pickup_appt_required : "No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">  Appt confirmed:</div>
            <div className="tooltip_text_right">  {it.picup_confirmed ? it.picup_confirmed : "No Data"}</div>
          </div>

        </div>
        <div className="tooltip_rightPu">
        </div>
      </div>
      <div className="main_tooltip_section tooltip_inner_gap" >
        <div className="tooltip_left">
          <div className="tooltip_single_sec mt_5 ">
            <div className="tooltip_text_left">Planned Arrive : </div>
            <div className="tooltip_text_right">  {formatDate(it.pickup_planned_arrive, it.pickup_timezone)}</div>
          </div>

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Planned Depart : </div>
            <div className="tooltip_text_right">  {formatDate(it.delivery_planned_depart, it.delivery_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA: </div>
            <div className="tooltip_text_right"> {formatDate(it.pickup_eta_utc, it.pickup_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Arrival : </div>
            <div className="tooltip_text_right">  {formatDate(it.pickup_actual_arrive, it.pickup_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Depart : </div>
            <div className="tooltip_text_right">  {formatDate(it.pickup_actual_depart, it.pickup_timezone)}</div>
          </div>
        </div>
        <div className="tooltip_middle">
        </div>
        <div className="tooltip_rightPu">
        </div>
      </div>
    </span>
  );
}

const Order2PBToolTip = (props) => {
  const it = props.it;

  return (
    <span className="tooltip-adjust">

      <div className="main_tooltip_section">

        <div className="tooltip_left">

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Order: </div>
            <div className="tooltip_text_right">{it.orderid}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Move: </div>
            <div className="tooltip_text_right">{it.move_id}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Brokerage: </div>
            <div className="tooltip_text_right">{it.brokerage}</div>
          </div>
          <div className="tooltip_single_sec pt_20">
            <div className="tooltip_text_left fs_20">Pickup:</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_right">
              <p className="tooltip_text_right">

                {it.company_location_id ?
                  (`${it.company_location_id} - ${it.location_name}`)
                  : "No Data"
                }
                <br />
                {it.address1 ?
                  (`${it.address1}`)
                  : null
                }
                <br />
                {it.city ?
                  (`${it.city}, ${it.state}, ${it.zip} `)
                  : null
                }
              </p>
            </div>
          </div>
          <div className="tooltip_single_sec pt_20">
            <div className="tooltip_text_left">Planned Arrival Time: </div>
            <div className="tooltip_text_right">{formatDate(it.pickup_planned_arrive, it.pickup_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Planned Depart Time: </div>
            <div className="tooltip_text_right">{formatDate(it.pickup_planned_depart, it.pickup_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA: </div>
            <div className="tooltip_text_right"> {formatDate(it.pickup_eta_utc, it.pickup_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Arrival Time: </div>
            <div className="tooltip_text_right">{formatDate(it.pickup_actual_arrive, it.pickup_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Depart Time: </div>
            <div className="tooltip_text_right">{formatDate(it.pickup_actual_depart, it.pickup_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Distance: </div>
            <div className="tooltip_text_right">{it.distance} Miles</div>
          </div>
        </div>
        <div className="tooltip_middle">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left"> Type: </div>
            <div className="tooltip_text_right">{it.stop_pickup__type}</div>
          </div>

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left"> Type: </div>
            <div className="tooltip_text_right">{it.move_type}</div>
          </div>
        </div>
        <div className="tooltip_right">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Status: </div>
            <div className="tooltip_text_right">{it.order_status}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Status: </div>
            <div className="tooltip_text_right">{it.movement_status}</div>
          </div>
          <div className="tooltip_single_sec pt_20">
            <div className="tooltip_text_left fs_20">Delivery: </div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_right">
              <p className="tooltip_text_right">
                {it.company_location_id1 ?
                  (`${it.company_location_id1} - ${it.location_name1}`)
                  : "No Data"
                }
                <br />
                {it.address11 ?
                  (`${it.address11}`)
                  : null
                }

                <br />
                {it.city1 ?
                  (`${it.city1}, ${it.state1}, ${it.zip1} `)
                  : null
                }
              </p>
            </div>
          </div>
          <div className="tooltip_single_sec pt_20">
            <div className="tooltip_text_left">Planned Arrive: </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Planned Depart: </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA: </div>
            <div className="tooltip_text_right"> {"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Arrival Time: </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Depart Time: </div>
            <div className="tooltip_text_right">{"No Data"}</div>
          </div>
        </div>
      </div>
    </span>
  );
}

const Order3PBToolTip = (props) => {
  const it = props.it;

  return (
    <span className="tooltip-adjust">
      <div className="main_tooltip_section">
        <div className="tooltip_left">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Order: </div>
            <div className="tooltip_text_right">{it.orderid}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Move: </div>
            <div className="tooltip_text_right">{it.move_id}</div>
          </div>
        </div>
        <div className="tooltip_middle">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">  Type:</div>
            <div className="tooltip_text_right">  {it.stop_delivery_type}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">  Type:</div>
            <div className="tooltip_text_right">  {"No Data"}</div>
          </div>
        </div>
        <div className="tooltip_rightPu">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Status:</div>
            <div className="tooltip_text_right"> {it.order_status}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Status:</div>
            <div className="tooltip_text_right"> {it.movement_status}</div>
          </div>
        </div>
      </div>

      <div className="main_tooltip_section tooltip_inner_gap">
        <div className="tooltip_left">
          <div className="tooltip_single_sec  ">
            <div className="tooltip_text_left fs_20">{it.stop_delivery_type ? it.stop_delivery_type.toUpperCase() : "No Data"} : </div>
          </div>
          <div className="tooltip_single_sec">
            <p className="tooltip_text_right address_set">
              <p className="tooltip_text_right">
                {it.company_location_id1 ?
                  (`${it.company_location_id1} - ${it.location_name1}`)
                  : "No Data"
                }
                <br />
                {it.address11 ?
                  (`${it.address11}`)
                  : null
                }
                <br />
                {it.city1 ?
                  (`${it.city1}, ${it.state1}, ${it.zip1} `)
                  : null
                }
              </p>
            </p>
          </div>
        </div>
        <div className="tooltip_middle">
        </div>
      </div>
      <div className="main_tooltip_section tooltip_inner_gap1">
        <div className="tooltip_left">
          <div className="tooltip_single_sec ">
            <div className="tooltip_text_left">Early Pickup Appt: </div>
            <div className="tooltip_text_right"> {formatDate(it.delivery_sched_arrive_early_utc, it.delivery_timezone)}</div>
          </div>
          <div className="tooltip_single_sec ">
            <div className="tooltip_text_left">Late Pickup Appt: </div>
            <div className="tooltip_text_right"> {formatDate(it.delivery_sched_arrive_late_utc, it.delivery_timezone)}</div>
          </div>
        </div>
        <div className="tooltip_middle">
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">  Appt Rq'd:</div>
            <div className="tooltip_text_right">  {"No Data"}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">  Appt confirmed:</div>
            <div className="tooltip_text_right">  {"No Data"}</div>
          </div>
        </div>
        <div className="tooltip_rightPu">
        </div>
      </div>
      <div className="main_tooltip_section tooltip_inner_gap" >
        <div className="tooltip_left">
          <div className="tooltip_single_sec mt_5 ">
            <div className="tooltip_text_left">Planned Arrive : </div>
            <div className="tooltip_text_right"> {formatDate(it.delivery_planned_arrive, it.delivery_timezone)}</div>
          </div>

          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Planned Depart : </div>
            <div className="tooltip_text_right">   {formatDate(it.delivery_planned_depart, it.delivery_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">ETA: </div>
            <div className="tooltip_text_right"> {formatDate(it.delivery_eta_utc, it.delivery_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Arrival : </div>
            <div className="tooltip_text_right">  {formatDate(it.delivery_actual_arrive, it.delivery_timezone)}</div>
          </div>
          <div className="tooltip_single_sec">
            <div className="tooltip_text_left">Actual Depart : </div>
            <div className="tooltip_text_right">  {formatDate(it.delivery_actual_depart, it.delivery_timezone)}</div>
          </div>
        </div>

        <div className="tooltip_middle">
        </div>
        <div className="tooltip_rightPu">
        </div>
      </div>
    </span>
  );
}

export { DriverTooltip, NonDriverTooltip, NestedPBToolTip, TrailerPBToolTip, SamsaraPBToolTip, OrderPBToolTip, Order2PBToolTip, Order3PBToolTip };
export default PBTooltip;