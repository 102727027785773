import * as React from 'react';
import { SchedulerFooter } from '@progress/kendo-react-scheduler';
import { ToolbarItem, ToolbarSeparator, ToolbarSpacer } from '@progress/kendo-react-buttons';
import { SchedulerConfigContext } from './tankWash';
import { ToolbarDropdown } from './toolbar-dropdown';

export const CustomFooter = props => {
  const [slotDivision, setSlotDivision] = React.useContext(SchedulerConfigContext).slotDivision;
  const [slotDuration, setSlotDuration] = React.useContext(SchedulerConfigContext).slotDuration;
  const handleDivisionChange = React.useCallback(event => {
    setSlotDivision(event.target.value);
  }, [setSlotDivision]);
  const handleDurationChange = React.useCallback(event => {
    setSlotDuration(event.target.value);
  }, [setSlotDuration]);
  return <SchedulerFooter>
        <ToolbarSpacer />
        <ToolbarItem>
          <ToolbarDropdown text="Slot Division" value={slotDivision} data={[1, 2]} onChange={handleDivisionChange} />
        </ToolbarItem>
        <ToolbarSeparator />
        <ToolbarItem>
          <ToolbarDropdown text="Slot Duration" value={slotDuration} data={[30, 60]} onChange={handleDurationChange} />
        </ToolbarItem>
      </SchedulerFooter>;
};