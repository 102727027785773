import { useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { makeStyles } from "@material-ui/core/styles";
import set from "date-fns/set/index.js";
import ProgressBar from "react-bootstrap/ProgressBar";
import UserService from "../../services/userService";
import TerminalService from "../../services/terminalService";
const PlanningprofileUsers = (props) => {
  const { profileById } = props
  console.log("rajat", profileById)

  const [alluserplanningprofile, setallplanningprofile] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  console.log("tttt", alluserplanningprofile)
  const useStyles = makeStyles((theme) => ({
    statuscolor: {
      fontWeight: "bold",
      textAlign: "center !important",
      fontSize: 15,
    },
  }));
  const classes = useStyles();
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [
        {
          field: "isActive",
          operator: "eq",
          value: true,
        },
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });
  const [dataResult, setDataResult] = useState(process(alluserplanningprofile, dataState));
  console.log("dataResult", dataResult)
  const dataStateChange = (event) => {
    setDataResult(process(alluserplanningprofile, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {
    setDataResult(process(alluserplanningprofile, dataState));
  }, [alluserplanningprofile]);

  useEffect(async () => {
    setIsLoading(true);

    const terminalService = new TerminalService()

    const planningprofile_user = await terminalService.getUsersbyPlanningprofileid(profileById.id)
    setallplanningprofile(planningprofile_user)
    setIsLoading(false);

  }, [])
  return (
    <>
    < div className="row">
      <div className="col-xl-12">    
     <div className="card card_shadow">
      <div className="card-body special_card_padding_users">
      <div className="table-responsive">
        {alluserplanningprofile.length >0 ? (
          <Grid
            filter={dataState.filter}
            sort={dataState.sort}
            sortable={true}
            filterable={true}
            //filterOperators={filterOperators}
            pageable={{
              pageSizes: [5, 10, 20, 25, 50, 100],
              info: true,
              previousNext: true,
              buttonCount: 10
            }}
            resizable={true}
            skip={dataState.skip}
            take={dataState.take}
            data={dataResult}
            onDataStateChange={dataStateChange}
          //onRowClick={(e) => props.parentcallback(true, e.dataItem)}
          >
            <GridColumn
              field="isActive"
              sortable={true}
              cell={(e) => {
                return (
                  <td
                    className={classes.statuscolor}
                    style={{
                      color: e.dataItem.isActive ? "#259125" : "#FF0000",
                    }}
                  >
                    {e.dataItem.isActive ? "True" : "False"}
                  </td>
                );
              }}
              title="Is Active"
              filterable={true}
              filter={"boolean"}
            />

            <GridColumn
              field="name"
              title="Name"
              filterable={true}
              cell={(e) => {
                return <td>{e.dataItem.name}</td>;
              }}
            />

            <GridColumn
              field="email"
              title="Email"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.email
                      ? e.dataItem.email
                      : ""}
                  </td>
                );
              }}
            />


            <GridColumn
              field="phone"
              title="Phone"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.phone
                      ? e.dataItem.phone
                      : ""}
                  </td>
                );
              }}
            />
          </Grid>
        ) : isLoading ? (
          <div>
            <ProgressBar animated now={100} />
            <div className="middle loader--text1"> </div>
          </div>
        ) : (
          <div className="text-center">No data found</div>
        )}
      </div>
      </div>
      </div>
      </div>
      </div>

    </>
  );
};

export default PlanningprofileUsers;
