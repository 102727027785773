import React, { useState, useEffect, useContext, useRef } from "react";
import "./planner.css";
import "./planner-top.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import PlanningBoardService from "../../services/planningBoardService";
import { DateTime } from "luxon";
import PBTooltip, { DriverTooltip, NonDriverTooltip, NestedPBToolTip, TrailerPBToolTip, SamsaraPBToolTip, OrderPBToolTip, Order2PBToolTip, Order3PBToolTip } from "./pbtooltips";

const PBBlock = (props) => {
  const widthRef = useRef(null);
  const [boxWidth, setBoxWidth] = useState(1);
  const [block, setBlock] = useState(props.block);
  const [impactViewModal, setImpactViewModal] = useState(false);
  const [allimpact, setallimpact] = useState([]);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 5,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });
  const [dataResult, setDataResult] = useState(
    process(allimpact, dataState)
  );
  const dataStateChange = (event) => {
    setDataResult(process(allimpact, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {
    setDataResult(process(allimpact, dataState));
  }, [allimpact]);


  useEffect(() => {
    setBoxWidth(parseInt(widthRef.current.clientWidth));
  }, []);

  useEffect(() => {
    const blockData = block.map((data, currentIndex) => {
      const xp = getXPosition(data.start);
      const of = getXPosition(data.OF);
      const pu = getXPosition(data.PU);
      const mv = getXPosition(data.MV);
      const del = getXPosition(data.DEL);
      const drawStartPercentage = getXPositionPercentage(data.start)
      const ofPercentage = getXPositionPercentage(data.OF);
      const puPercentage = getXPositionPercentage(data.PU);
      const mvPercentage = getXPositionPercentage(data.MV);
      let delPercentage = getXPositionPercentage(data.DEL);
      let totalBlockPercentage = puPercentage + mvPercentage + delPercentage;
      let middleBlockPercentage = totalBlockPercentage;

      //Adding Preprocessing Logic as not Every Header Matches Every Item

      //Modify Block
      if ((block.length - 1) !== currentIndex) {
        if (data.trailerid === block[currentIndex + 1].trailerid && block[currentIndex + 1].move_type == 'E') {
          //Extend The Block To Fill In The Next One
          middleBlockPercentage = middleBlockPercentage + getXPositionPercentage(block[currentIndex + 1].MV)
        }
      }

      //Remove Block
      if ((0) !== currentIndex) {
        if (data.trailerid === block[currentIndex - 1].trailerid && data.move_type == 'E') {
          //Extend The Block To Fill In The Next One
          middleBlockPercentage = 0
        }
      }


      return {
        ...data,
        drawStart: xp,
        OFX: of,
        PUX: pu,
        MVX: mv,
        DELX: del,
        drawStartPercentage: drawStartPercentage,
        ofWidthPercentage: ofPercentage,
        puWidthPercentage: puPercentage,
        mvWidthPercentage: mvPercentage,
        delWidthPercentage: delPercentage,
        totalWidthPercentage: totalBlockPercentage,
        middleWidthPercentage: middleBlockPercentage,
      };
    });


    setBlock(blockData);
  }, [boxWidth]);



  const getClassName = function (width, reachedOnTime) {
    return reachedOnTime
      ? "dragable_bottom_section_box dragable_width_" +
      width +
      " dragable-planner-blue"
      : "dragable_bottom_section_box dragable_width_" +
      width +
      " dragable-planner-yellow";
  };

  const getClassNameStatus = function (width, status) {
    switch (status) {
      case "green":
        return (
          "dragable_bottom_section_box cp dragable_width_" +
          width +
          " dragable-planner-green"
        );
      case "yellow":
        return (
          "dragable_bottom_section_box dragable_width_" +
          width +
          " dragable-planner-yellow"
        );
      case "red":
        return (
          "dragable_bottom_section_box dragable_width_" +
          width +
          " dragable-planner-red"
        );
    }
  };

  const getClassNameOf = function (width) {
    return (
      "dragable_section_box_of dragable_width_" +
      width +
      " dragable-planner-darkblue"
    );
  };

  const getXPosition = function (minutes) {
    const value = parseInt((boxWidth * minutes) / 1440);
    return value;

  };

  const getXPositionPercentage = function (minutes) {
    const value = minutes / 1440;
    const percentage = value * 100;
    return percentage;

  };

  const formatDate = (epoch_date, tzone) => {
    return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
  }

  const getTooltips = function (it) {
    let toolText = "";

    if (!it.loaded) {
      toolText = view === "driver" ? "T-" + it.trailerid : "D-" + it.driverid;
    } else {
      toolText =
        "O-" +
        it.orderid +
        "   " +
        (view === "driver" ? "T-" + it.trailerid : "D-" + it.driverid);
    }
    return toolText;
  };

  const getTopClassName = function (width, it) {
    let cssTopClass = "dragable_top_section_box";
    switch (it.zmitStatus) {
      case "green":
        cssTopClass = cssTopClass + " dragable_top_section_box_border_green";
        break;
      case "orange":
        cssTopClass = cssTopClass + " dragable_top_section_box_border_orange";
        break;
      case "white":
        cssTopClass = cssTopClass + " dragable_top_section_box_border_white";
        break;
      case "carrierView":
        cssTopClass = cssTopClass + " dragable_top_section_box_border_white";
        break;
      case "trailerView":
        cssTopClass = cssTopClass + " dragable_top_section_box_border_white";
        break;
    }

    if (width > 5) {
      cssTopClass = cssTopClass + " dragable_top_section_box_border";
    } else {
      cssTopClass = cssTopClass + " dragable_top_section_box_border_nopadding";
    }

    if (it.loaded) return cssTopClass + " dragable_width_" + width;
    else return cssTopClass + " dragable_width_" + width; // + " dragable-planner-gray";
  };


  const getMiddleClassName = function (width, it, disblock, index) {
    let cssTopClass = "dragable_middle_section_box";
    switch (it.zmitStatus) {
      case "green":
        cssTopClass = cssTopClass + " dragable_top_section_box_border_green";
        break;
      case "orange":
        cssTopClass = cssTopClass + " dragable_top_section_box_border_orange";
        break;
      case "white":
        cssTopClass = cssTopClass + " dragable_top_section_box_border_white";
        break;
      case "carrierView":
        cssTopClass = cssTopClass + " dragable_top_section_box_border_white";
        break;
      case "trailerView":
        cssTopClass = cssTopClass + " dragable_top_section_box_border_white";
        break;
    }

    if (width > 5) {
      cssTopClass = cssTopClass + " dragable_top_section_box_border";
    } else {
      cssTopClass = cssTopClass + " dragable_top_section_box_border_nopadding";
    }

    if (it.loaded) return cssTopClass + " dragable_width_" + width;
    else return cssTopClass + " dragable_width_" + width;
  };

  const getTopClassNameOf = function (width) {
    return (
      "dragable_top_section_box_of  dragable_top_section_box_border_of dragable_width_" +
      width
    );
  };

  const handleTooltipClick = (moveType, status, data) => {
    const planningboardService = new PlanningBoardService();
    if (moveType == "pickup") {
      if (data.pickup_status !== "green") {
        const impactViewData = planningboardService
          .getImapctView(
            data.pickup_planned_depart,
            data.driverid,
            data.trailerid
          )
          .then((res) => {
            setallimpact(res.data);
            setImpactViewModal(true);
          })
          .catch((err) => {
          });
      }
    }
    else if (moveType == "delivery") {
      if (data.delivery_status !== "green") {
        const impactViewData = planningboardService
          .getImapctView(
            data.delivery_planned_depart,
            data.driverid,
            data.trailerid
          )
          .then((res) => {
            setallimpact(res.data);
            setImpactViewModal(true);
          })
          .catch((err) => {
          });
      }
    }
  };

  const view = props.view;

  return (
    <>
      {block.map((it, index) => (
        <div ref={widthRef} className="dragable_wrapper">
          <div>
            {
              <>
                {it.MVX + it.PUX + it.DELX > 0 ? (

                  // Orders tooltip
                  <PBTooltip
                    tooltip={
                      <>
                        {view === "driver" ? (
                          <DriverTooltip it={it} />
                        ) : (
                          <NonDriverTooltip it={it} />
                        )}
                      </>
                    }
                  >
                    <>
                      {it.move_type === "E" ? (
                        <div></div>
                      ) : (
                        <PBTooltip
                          tooltip={
                            <NestedPBToolTip it={it} />
                          }
                        >
                          <div
                            className={getTopClassName(
                              it.MVX + it.PUX + it.DELX,
                              it
                            )}
                            style={{
                              width: it.totalWidthPercentage + "%",
                              left: it.drawStartPercentage + "%",
                              position: "absolute"
                            }}
                          >
                            <div className="dragable_top_section_box_minute_text blockwidth_fixed">
                              {it.orderid}
                            </div>
                          </div>
                        </PBTooltip>
                      )}
                      {it.middleWidthPercentage == 0 ? (<div> </div>) : (
                        <div
                          className={getMiddleClassName(
                            it.MVX + it.PUX + it.DELX,
                            it, block, index
                          )}
                          style={{
                            width: it.middleWidthPercentage + "%",
                            left: it.drawStartPercentage + "%",
                            position: "absolute"
                          }}
                        >
                          {view === "driver" ? (
                            <PBTooltip
                              tooltip={
                                <TrailerPBToolTip it={it} />
                              }
                            >
                              <div className="dragable_top_section_box_minute_text blockwidth_fixed">
                                {it.trailerid}
                              </div>
                            </PBTooltip>
                          ) : (
                            <PBTooltip tooltip={
                              <SamsaraPBToolTip it={it} />
                            }>

                              <div className="dragable_top_section_box_minute_text blockwidth_fixed">
                                {it.driverid}
                              </div>

                            </PBTooltip>

                          )}
                        </div>)}
                    </>
                  </PBTooltip>
                ) : (
                  ""
                )}
                {/* {it.OFX === 0 ? (
                  ""
                ) : (
                  <div
                    className={getTopClassNameOf(it.OFX)}
                    style={{ 
                      width: it.ofWidthPercentage + "%",
                      left: it.drawStartPercentage + "%",
                      position: "absolute"
                    }}
                  >
                    <div className="dragable_top_section_box_minute_text"></div>
                  </div>
                )} */}

                <div className="dragable_bottom_section cp">
                  {it.PU === 0 ? (
                    ""
                  ) : (
                    <PBTooltip tooltip={
                      <OrderPBToolTip it={it} />
                    }
                      onClick={() => handleTooltipClick("pickup", it.pickup_status, it)}
                    >
                      <div
                        className={getClassNameStatus(it.PUX, it.pickup_status)}
                        style={{
                          width: it.puWidthPercentage + "%",
                          left: it.drawStartPercentage + "%",
                          position: "absolute"
                        }}
                      >
                        <div className="dragable_bottom_section_text">
                          {" "}
                          {it.pickup_type}{" "}
                        </div>
                      </div>
                    </PBTooltip>
                  )}
                  {+it.MV === 0 ? (
                    ""
                  ) : (
                    <PBTooltip tooltip={
                      <Order2PBToolTip it={it} />
                    }>
                      <div
                        className={getClassName(it.MVX, true)}
                        style={{
                          width: it.mvWidthPercentage + "%",
                          left: it.drawStartPercentage + it.puWidthPercentage + "%",
                          position: "absolute"
                        }}
                      >
                        <div className="dragable_bottom_section_text">
                          {it.move_type}
                        </div>
                      </div>
                    </PBTooltip>
                  )}
                  {it.DEL === 0 ? (
                    ""
                  ) : (
                    <PBTooltip tooltip={
                      <Order3PBToolTip it={it} />
                    }
                      onClick={() => handleTooltipClick("delivery", it.delivery_status, it)}
                    >
                      <div
                        className={getClassNameStatus(
                          it.DELX,
                          it.delivery_status
                        )}
                        style={{
                          width: it.delWidthPercentage + "%",
                          left: it.drawStartPercentage + it.puWidthPercentage + it.mvWidthPercentage + "%",
                          position: "absolute"
                        }}
                      >
                        <div className="dragable_bottom_section_text">
                          {it.delivery_type}{" "}
                        </div>
                      </div>
                    </PBTooltip>
                  )}
                  {it.OF === 0 ? (
                    ""
                  ) : (
                    <div
                      className={getClassNameOf(it.OFX)}
                      style={{ 
                        width: it.ofWidthPercentage + "%",
                        left: it.drawStartPercentage + "%",
                        position: "absolute"
                    }}
                    >
                      <div className="dragable_bottom_section_text">
                        {it.move_type}
                      </div>
                    </div>
                  )}
                </div>
              </>
            }
          </div>
        </div>
      ))}

      <Modal
        show={impactViewModal}
        onHide={() => setImpactViewModal(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Impact View
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid
            filter={dataState.filter}
            filterable={true}
            sort={dataState.sort}
            sortable={true}
            pageable={{
              buttonCount: 10,
              info: true,
              previousNext: true,
              pageSizes: true,
            }}
            resizable={true}
            skip={dataState.skip}
            take={dataState.take}
            data={dataResult}
            onDataStateChange={dataStateChange}
          >
            <GridColumn
              field="driver_id"
              title="Driver ID"
              width="200px"
              filterable={true}
              cell={(e) => {
                return <td>{e?.dataItem?.driver_id}</td>;
              }}
            />
            <GridColumn
              field="trailer_id"
              title="Trailer ID"
              width="200px"
              filterable={true}
              cell={(e) => {
                return <td>{e.dataItem.trailer_id}</td>;
              }}
            />
            <GridColumn
              field="terminal"
              title="Terminal"
              width="200px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.terminal ? e.dataItem.terminal : ""}
                  </td>
                );
              }}
            />
            <GridColumn
              field="load_id"
              title="Load ID"
              width="200px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>{e.dataItem.load_id ? e.dataItem.load_id : ""}</td>
                );
              }}
            />
            <GridColumn
              field="loaded"
              title="Loaded"
              width="200px"
              filterable={true}
            />
            <GridColumn
              field="driver_load_unload"
              title="Driver Load Unload"
              width="200px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>{e.dataItem.driver_load_unload ? e.dataItem.driver_load_unload : ""}</td>
                );
              }}
            />
            <GridColumn
              field="lm_order_id"
              title="Order ID"
              width="200px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.lm_order_id
                      ? e.dataItem.lm_order_id
                      : ""}
                  </td>
                );
              }}
            />
            <GridColumn
              field="stop_type"
              title="Stop Type"
              width="200px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.stop_type
                      ? e.dataItem.stop_type
                      : ""}
                  </td>
                );
              }}
            />
            <GridColumn
              field="planned_arrive_utc"
              title="Planned Arrive"
              width="200px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.planned_arrive_utc
                      ? formatDate(e.dataItem.planned_arrive_utc)
                      : ""}
                  </td>
                );
              }}
            />

            <GridColumn
              field="planned_depart_utc"
              title="Planned Depart"
              width="200px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.planned_depart_utc
                      ? formatDate(e.dataItem.planned_depart_utc)
                      : ""}
                  </td>
                );
              }}
            />
            <GridColumn
              field="sched_arrive_early_utc"
              title="Scheduled Arrive Early"
              width="200px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.sched_arrive_early_utc
                      ? formatDate(e.dataItem.sched_arrive_early_utc)
                      : ""}
                  </td>
                );
              }}
            />
            <GridColumn
              field="sched_arrive_late_utc"
              title="Scheduled Arrive Late"
              width="200px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.sched_arrive_late_utc
                      ? formatDate(e.dataItem.sched_arrive_late_utc)
                      : ""}
                  </td>
                );
              }}
            />
            <GridColumn
              field="actual_arrival_utc"
              title="Actual Arrival"
              width="200px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.actual_arrival_utc
                      ? formatDate(e.dataItem.actual_arrival_utc)
                      : ""}
                  </td>
                );
              }}
            />
            <GridColumn
              field="actual_departure_utc"
              title="Actual Depart"
              width="200px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.actual_departure_utc
                      ? formatDate(e.dataItem.actual_departure_utc)
                      : ""}
                  </td>
                );
              }}
            />
            <GridColumn
              field="eta_utc"
              title="ETA"
              width="200px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.eta_utc
                      ? formatDate(e.dataItem.eta_utc)
                      : ""}
                  </td>
                );
              }}
            />
            <GridColumn
              field="lm_location_name"
              title="Customer Information"
              width="200px"
              filterable={true}
              cell={(e) => {
                return (
                  <td>
                    {e.dataItem.lm_location_name
                      ? e.dataItem.lm_location_name
                      : ""}
                  </td>
                );
              }}
            />
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => setImpactViewModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PBBlock;
